import React from 'react'
import '../../App.css'

export default function Qualifications() {
    return(
        <div className='container'>
            
            <div>
                <h1 className='qualificationsmod'>QUALIFICATIONS</h1>
                
            </div>
            <iframe className="pdf" src="Files/Resume LP 7.27.pdf">

            </iframe>
        </div>
        
    )
}